<template>
  <ValidationProvider
    :name="$t(label)"
    :rules="required ? 'required' : ''"
    :vid="label"
    ref="fieldCurrency"
    slim
  >
    <v-select
      v-model="iCurrencyId"
      v-bind="$attrs"
      slot-scope="{ errors, valid }"
      :error-messages="errors"
      :success="valid"
      :label="$t(label)"
      :items="items"
      :menu-props="{ offsetY: true }"
      @change="onChange"
      item-value="currency_id"
      item-text="name"
      clearable
      outlined
      dense
    >
      <template #item="{ item }">
        <name-with-avatar
          :path="getFlag(item.currency.code)"
          :name="item.currency.name"
        />
      </template>
    </v-select>
  </ValidationProvider>
</template>

<script lang="ts">
import { CurrencyRateData, CurrencyRates } from "@planetadeleste/vue-mc-gw";
import { Vue, Component, Prop, VModel } from "vue-property-decorator";
import NameWithAvatar from "@/components/common/NameWithAvatar.vue";

@Component({
  components: { NameWithAvatar },
})
export default class CurrencyRateSelect extends Vue {
  @VModel({ type: Number }) iCurrencyId!: number;
  @Prop({ type: String, default: "currency" }) readonly label!: string;
  @Prop(Boolean) readonly required!: boolean;
  @Prop(Array) readonly ids!: number[];

  obCollection: CurrencyRates = new CurrencyRates();

  mounted() {
    this.$nextTick(this.load);
  }

  async load() {
    this.obCollection.clear();

    await this.obCollection.fetch();
  }

  onChange(iValue: number) {
    const obCurrencyRate = this.obCollection.find({ currency_id: iValue });
    this.$emit("change", obCurrencyRate);
  }

  getFlag(sCode: string) {
    return require(`@icon/flags/${sCode.toLowerCase()}.png`);
  }

  get items() {
    return this.ids && this.ids.length
      ? this.obCollection.filter((obCurrency: CurrencyRateData) =>
          this.ids.includes(obCurrency.currency_id)
        ).models
      : this.obCollection.models;
  }

  get loading() {
    return this.obCollection.loading;
  }
}
</script>
